<template>
  <el-dialog
    title="匯入"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <p>請先點擊下載<span class="text-btn" @click="getExampleFile">範例</span>，填寫完後，再上傳該檔案至下方。</p>
    <div
      class="drop-zone"
      @drop.prevent="onDrop"
      @dragenter.prevent
      @dragover.prevent
      @dragleave.prevent
    >
      <div v-if="!importFile" class="flex flex-col items-center justify-center">
        <img src="@/assets/icon/cloud_upload.svg">
        <div>
          拖移上傳或是
          <label for="import-btn" class="text-btn">選取檔案</label>
          <input id="import-btn" ref="fileInput" type="file" name="import-btn" @change="fileInput">
        </div>
      </div>
      <div v-if="importFile" class="flex flex-col items-center justify-center">
        <img src="@/assets/icon/check.svg">
        <p>檔案選取成功</p>
        <div class="flex">
          <p class="text-btn">{{ importFile.name }}</p>
          <span
            class="material-icons cursor-pointer"
            style="width: 16px; height: 16px; margin-left: 10px"
            @click="importFile = null"
          >delete_filled</span>
        </div>
      </div>
    </div>
    <p>支援檔案：大小需 &#60; 5 MB，檔案格式僅支援 CSV、xlsx </p>
    <p style="margin-top: 4px">
      注意：若檔案內有多筆相同統編之重複名單，系統將自動忽略不匯入，<br>
      請在上傳完成後，下載「未匯入名單檔案」進行篩選後再次匯入。
    </p>
    <div slot="footer" class="dialog-footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="handleConfirm">確認</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { UploadFile } from '@/api/file'
import { GetImportSchema } from '@/api/import'
import { mapGetters } from 'vuex'
import { ExportExcel } from '@/utils/excel'
import { find, get } from 'lodash'

export default {
  name: 'DropImportDialog',
  data: () => ({
    importFile: null,
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  methods: {
    getFile (target) {
      const input = target
      const fileData = {
        file: null,
        name: null,
        blob: null,
        raw: null,
      }
      if (!input.files) return null
      if (!input.files[0]) return null
      fileData.raw = input.files[0]
      fileData.blob = URL.createObjectURL(input.files[0])
      var reader = new FileReader()
      reader.onload = (e) => {
        fileData.file = e.target.result
      }
      reader.readAsDataURL(input.files[0])
      fileData.name = input.files[0].name
      return fileData
    },
    async uploadFile () {
      const [res, err] = await UploadFile({
        file: this.importFile.raw,
        type: 'batchImport',
        name: this.importFile.name,
      })
      if (err) {
        this.$message.error(err)
        return false
      }
      return res
    },
    onDrop (e) {
      const fileData = this.getFile(e.dataTransfer)
      this.importFile = fileData
      console.log(fileData)
      this.$refs.fileInput.type = 'text'
      this.$refs.fileInput.type = 'file'
    },

    fileInput (e) {
      const fileData = this.getFile(e.target)
      console.log(fileData)
      this.importFile = fileData

      this.$refs.fileInput.type = 'text'
      this.$refs.fileInput.type = 'file'
    },
    async handleConfirm () {
      const result = await this.uploadFile()
      console.log(result)
      this.$emit('upload', result)
    },
    async getImportSchema () {
      const [res, err] = await GetImportSchema({ shopId: this.shop, type: 'CompanyDirectoryFirm' })
      if (err) {
        this.$message.error(err)
        return false
      }
      return res
    },
    async getExampleFile () {
      const res = await this.getImportSchema()
      if (!res) return

      const list = []
      for (const key in res.schema) {
        list.push({
          key,
          ...res.schema[key],
        })
      }
      list.sort((a, b) => a.order - b.order)

      const data = {}
      list.forEach(item => {
        data[item.text] = ''
      })
      ExportExcel([data], '範例', '範例')
    },
  },
}
</script>

<style lang="postcss" scoped>
.drop-zone {
  @apply flex flex-col justify-center items-center h-[187px];
  @apply border border-primary-100 border-dashed rounded-[4px] my-[16px];
}

.text-btn {
  @apply underline text-primary-100 cursor-pointer;
}

#import-btn {
  @apply hidden;
}
</style>
